import axios from 'axios';
import useJwt from '@src/auth/jwt/useJwt';

const token = useJwt.getTokenOnboarding();
const userData = JSON.parse(localStorage.getItem('userData') || '{}'); // Garante que é um objeto válido

const apiOnboarding = axios.create({
  baseURL: process.env.REACT_APP_API_ONBOARDING_URL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

if (userData?.email && userData?.name) {
  apiOnboarding.interceptors.request.use(
    (config) => {
      if (config.url?.includes('oauth/token')) {
        return config;
      }

      const minimalUserData = {
        email: userData.email,
        name: userData.name,
        systemOrigin: '',
      };

      minimalUserData.systemOrigin = process.env.REACT_APP_SYSTEM_ORIGIN || '';

      config.headers['Content-Data'] = JSON.stringify(minimalUserData);

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

export default apiOnboarding;
